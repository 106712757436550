<template>
  <v-container>
    <v-form @submit.prevent="submitResponse">
      <div>
        <p class="mb-3">
          a) Complete the following table for the indicated nuclide of the first 5 Noble gases
          (Column 18/8A elements). For the ratio, please express this in decimal form to 3 decimal
          places. Your Teaching Assistant (TA) will review your answers for this question. Please
          allow up to two days for your TA to review and potentially adjust your grade.
        </p>

        <v-simple-table class="mb-5 pl-4">
          <thead>
            <tr>
              <td></td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope1" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope2" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope3" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope4" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope5" />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Number of protons</td>
              <td v-for="question in protonQuestions" :key="question">
                <v-text-field v-model="inputs[question]"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>Number of neutrons</td>
              <td v-for="question in neutronQuestions" :key="question">
                <v-text-field v-model="inputs[question]"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>Ratio of protons to neutrons</td>
              <td v-for="question in ratioQuestions" :key="question">
                <v-text-field v-model="inputs[question]"></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <p class="mb-4">
          b) Describe any trend you discover in the way this ratio changes with increasing atomic
          number.
        </p>
        <s-textarea v-model="inputs['trendDescription']" outlined class="mb-4"></s-textarea>
      </div>
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import ChemicalIsotopeValue from '@/tasks/components/ChemicalIsotopeValue.vue';

export default {
  name: 'OleMissPreLab11Part1',
  components: {
    ChemicalIsotopeValue,
    AiLoadingOverlay,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        numProtons1: null,
        numProtons2: null,
        numProtons3: null,
        numProtons4: null,
        numProtons5: null,
        numNeutrons1: null,
        numNeutrons2: null,
        numNeutrons3: null,
        numNeutrons4: null,
        numNeutrons5: null,
        protonNeutronRatio1: null,
        protonNeutronRatio2: null,
        protonNeutronRatio3: null,
        protonNeutronRatio4: null,
        protonNeutronRatio5: null,
        trendDescription: null,
      },
      protonQuestions: ['numProtons1', 'numProtons2', 'numProtons3', 'numProtons4', 'numProtons5'],
      neutronQuestions: [
        'numNeutrons1',
        'numNeutrons2',
        'numNeutrons3',
        'numNeutrons4',
        'numNeutrons5',
      ],
      ratioQuestions: [
        'protonNeutronRatio1',
        'protonNeutronRatio2',
        'protonNeutronRatio3',
        'protonNeutronRatio4',
        'protonNeutronRatio5',
      ],
    };
  },
  computed: {
    isotope1() {
      return this.taskState.getValueBySymbol('isotope1').content;
    },
    isotope2() {
      return this.taskState.getValueBySymbol('isotope2').content;
    },
    isotope3() {
      return this.taskState.getValueBySymbol('isotope3').content;
    },
    isotope4() {
      return this.taskState.getValueBySymbol('isotope4').content;
    },
    isotope5() {
      return this.taskState.getValueBySymbol('isotope5').content;
    },
  },
};
</script>

<style scoped></style>
